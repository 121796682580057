import { prop } from 'styled-tools';
import styled from 'styled-components';
import StyledTd from '../../TableBlock/styled/StyledTd';
import StyledTh from '../../TableBlock/styled/StyledTh';
import { HeaderWrapper } from '../../TableBlock/TableRows';
import StyledTable from '../../TableBlock/styled/StyledTable';

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${StyledTable} {
    tr {
      border-bottom: 0.0625rem solid ${prop('theme.colors.greyLightest')};
      border-left: 0.0625rem solid ${prop('theme.colors.greyLightest')};
      border-right: 0.0625rem solid ${prop('theme.colors.greyLightest')};
      display: block;
      margin-bottom: 0;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        display: table-row;
        border: none;
      }
    }

    ${StyledTh} {
      font-size: 0.875rem;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        padding: 0.875rem;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        font-size: 1rem;
        padding: 0.875rem 1rem;
      }
    }

    ${StyledTd} {
      border: none;
      display: flex;
      empty-cells: show;
      font-size: 0.875rem;
      padding: 0.625rem 0.875rem;
      text-align: right;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        border-left: 0.0625rem solid ${prop('theme.colors.greyLightest')};
        border-right: 0.0625rem solid ${prop('theme.colors.greyLightest')};
        display: table-cell;
        padding: 0.875rem;
        text-align: left;
        vertical-align: top;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        font-size: 1rem;
        padding: 0.875rem 1rem;
      }

      > * {
        font-size: 0.875rem;
        line-height: inherit;
        width: 50%;

        @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
          width: auto;
        }

        @media (min-width: ${prop('theme.breakpoints.xl')}) {
          font-size: 1rem;
        }
      }
    }
  }

  ${HeaderWrapper} {
    display: flex;
    text-align: left;

    @media (min-width: 1040px) {
      display: none;
    }
  }

  &&& {
    thead {
      display: none;

      @media (min-width: ${prop('theme.tableBreakpoints.lg')}) {
        display: table-header-group;
      }
    }
  }
`;

export default DataContainer;
