import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import ContractsTitle from './ContractsTitle';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    align-items: center;
    flex-direction: row;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 300;
    min-width: fit-content;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1.875rem;
      margin-bottom: 0;
    }
  }

  .filter-dropdown {
    font-size: 0.875rem;
    margin-left: 1rem;
    max-width: 12.5rem;
    width: 50%;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      width: 100%;
    }

    &:first-child {
      @media (max-width: ${prop('theme.breakpoints.md')}) {
        margin: 0;
      }
    }

    > * {
      cursor: pointer;
    }
  }
`;

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
`;

const propTypes = {
  title: PropTypes.array,
  tooltip: PropTypes.array,
  dateOptions: PropTypes.array,
  symbolOptions: PropTypes.array,
  selectedDate: PropTypes.object,
  selectedSymbol: PropTypes.object,
  handleDateChange: PropTypes.func,
  handleSymbolChange: PropTypes.func,
};

const defaultProps = {
  title: [],
  tooltip: [],
  dateOptions: [],
  symbolOptions: [],
  selectedDate: null,
  selectedSymbol: null,
  handleDateChange: null,
  handleSymbolChange: null,
};

function ContractsHeader({
  title,
  tooltip,
  dateOptions,
  symbolOptions,
  selectedDates,
  selectedSymbol,
  handleDateChange,
  handleSymbolChange,
}) {
  const { startDate, endDate } = selectedDates;

  return (
    <HeaderContainer>
      <ContractsTitle title={title} tooltip={tooltip} />
      <SelectContainer>
        <Select
          className='filter-dropdown'
          onChange={handleSymbolChange}
          options={symbolOptions}
          placeholder='Symbol'
          value={selectedSymbol}
        />
        <Select
          className='filter-dropdown'
          onChange={handleDateChange}
          options={dateOptions}
          placeholder='Start Date'
          value={startDate.value && startDate}
          name='startDate'
        />
        <Select
          className='filter-dropdown'
          onChange={handleDateChange}
          options={dateOptions}
          placeholder='End Date'
          value={endDate.value && endDate}
          name='endDate'
        />
      </SelectContainer>
    </HeaderContainer>
  );
}

ContractsHeader.propTypes = propTypes;
ContractsHeader.defaultProps = defaultProps;

export default ContractsHeader;
