import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

import DataContainer from './styled/DataContainer';
import ResponsiveTable from '../TableBlock/ResponsiveTable';
import {
  checkForNumValue,
  findContractMonth,
  formatDecimals,
  trimFuturesSuffix,
} from 'utils/functions';

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  futuresSymbols: PropTypes.string,
  selectedDates: PropTypes.object,
  selectedSymbol: PropTypes.object,
};

const defaultProps = {
  data: {},
  futuresSymbols: '',
  selectedDates: {},
  selectedSymbol: {},
};

const ErrorMessage = styled.h3`
  margin-bottom: 0;
  padding: 1rem;
`;

function ContractsData({ contractData, selectedDates, selectedSymbol }) {
  const headerObject = {
    col1: 'Date',
    col2: 'Symbol',
    col3: 'Expiration',
    col4: 'Open',
    col5: 'Close',
    col6: 'High',
    col7: 'Low',
    col8: 'Last',
    col9: 'Volume',
    col10: 'Open Interest',
  };

  const endOfTodaysTradingDay = moment
    .tz('America/Chicago')
    .hour(16)
    .minute(15);
  const rightNow = moment.tz('America/Chicago');
  const startDateSelected = selectedDates.startDate.label;
  const endDateSelected =
    selectedDates.endDate.label || rightNow.format('MM/DD/YYYY');

  function formatContractData(contractData) {
    if (!contractData) {
      return;
    }
    let body = [];

    function filterBodyObject(bodyObject) {
      // Want to display only final data for the current day.
      if (bodyObject.col1_data !== rightNow.format('MM/DD/YYYY')) {
        body.push(bodyObject);
      } else if (
        bodyObject.col1_data === rightNow.format('MM/DD/YYYY') &&
        rightNow.isAfter(endOfTodaysTradingDay)
      ) {
        body.push(bodyObject);
      }
    }

    contractData.forEach(dataValue => {
      const bodyObject = {
        col1_data: moment(dataValue.time)
          .utc()
          .format('MM/DD/YYYY'),
        col2_data: trimFuturesSuffix(dataValue.eventSymbol),
        col3_data: findContractMonth(dataValue.eventSymbol),
        col4_data: formatDecimals(checkForNumValue(dataValue.open, 'n/a')),
        col5_data: formatDecimals(checkForNumValue(dataValue.close, 'n/a')),
        col6_data: formatDecimals(checkForNumValue(dataValue.high, 'n/a')),
        col7_data: formatDecimals(checkForNumValue(dataValue.low, 'n/a')),
        col8_data: formatDecimals(checkForNumValue(dataValue.last, 'n/a')),
        col9_data: checkForNumValue(dataValue.volume),
        col10_data: checkForNumValue(dataValue.openInterest),
      };

      filterBodyObject(bodyObject);
    });

    return body;
  }
  const formattedContractData = formatContractData(contractData);

  function filterArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return (
      array &&
      array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
          // ignores non-function predicates
          if (typeof filters[key] !== 'function') return true;
          return filters[key](item[key]);
        });
      })
    );
  }

  function displayCertainSymbols(symbols) {
    if (selectedSymbol && selectedSymbol.label !== 'All Symbols') {
      return symbols === selectedSymbol.label;
    } else {
      return symbols;
    }
  }

  const filterParameters = {
    col1_data: col1_data =>
      col1_data >= startDateSelected && col1_data <= endDateSelected,
    col2_data: col2_data => displayCertainSymbols(col2_data),
  };
  const filteredArray = filterArray(formattedContractData, filterParameters);

  const sortedBody = orderBy(
    filteredArray,
    ['col1_data', 'col2_data'],
    ['desc', 'asc']
  );

  if (!contractData) {
    return (
      <ErrorMessage>
        Sorry, there was an issue retrieving the contracts data!
      </ErrorMessage>
    );
  }

  if (sortedBody.length === 0) {
    return (
      <ErrorMessage>
        No data for the filter parameters selected. Check your filter
        parameters.
      </ErrorMessage>
    );
  }

  return (
    <DataContainer>
      <ResponsiveTable
        columns={headerObject}
        head={headerObject}
        rows={sortedBody}
      />
    </DataContainer>
  );
}

ContractsData.propTypes = propTypes;
ContractsData.defaultProps = defaultProps;

export default ContractsData;
