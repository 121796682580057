import React from 'react';
import first from 'lodash/first';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import Tooltip, { StyledReactTooltip, TooltipIcon } from '../Tooltip';
import RichTextWithDictionary from 'components/RichTextWithDictionary';

const TitleContainer = styled.div`
  display: flex;
  flex: 1 0 auto;

  h1 {
    margin-right: 0.25rem;
  }

  ${TooltipIcon} {
    margin-top: 0;
    position: relative;
    right: 0;
    top: -0.5rem;
  }

  ${StyledReactTooltip} {
    margin-left: 0;
    max-width: 18rem;
    z-index: 1250;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      max-width: 22rem;
    }

    &.place-left {
      &:after {
        margin-top: 0;
      }
    }
  }
`;

const propTypes = {
  title: PropTypes.array,
  tooltip: PropTypes.array,
};

const defaultProps = {
  title: [],
  tooltip: [],
};

function ContractsTitle({ title, tooltip }) {
  const titleText = title ? first(title).text : null;

  return (
    <>
      {tooltip && tooltip.length > 0 ? (
        <TitleContainer>
          <RichTextWithDictionary render={title} />
          {tooltip && (
            <Tooltip
              content={tooltip}
              place='bottom'
              triggerValue={titleText}
            />
          )}
        </TitleContainer>
      ) : (
        <RichTextWithDictionary render={title} />
      )}
    </>
  );
}

ContractsTitle.propTypes = propTypes;
ContractsTitle.defaultProps = defaultProps;

export default ContractsTitle;
