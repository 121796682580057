import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import first from 'lodash/first';
import { graphql } from 'gatsby';
import { Spinner } from 'reactstrap';

import SEO from '../components/seo';
import Layout from '../components/layout';
import MainCol from '../components/PageBody/MainCol';
import ContractsData from '../components/ContractsData';
import ContractsHeader from '../components/ContractsData/ContractsHeader';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import WidgetContainer from '../components/Widgets/styled/WidgetContainer';
import {
  buildAllFuturesSymbols,
  findContractExpirationDate,
  trimFuturesSuffix,
  dateSelectOptions,
  historicalContractsEndDate,
  historicalContractsYears,
} from 'utils/functions';
import { FUTURES_START_DATE_FORMATTED } from 'utils/constants';
import useFetchContractData from 'hooks/fetchContractData';

const SpinnerContainer = styled.div`
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ContractsPage({ data, errors }) {
  const doc = data.prismic.contracts_page;
  const {
    _meta,
    page_title,
    page_title_tooltip,
    seo_title,
    seo_meta_description,
  } = doc;
  const pageName = page_title ? first(page_title).text : '';
  const { lang } = _meta;
  const holidays = data.prismic.allEvents.edges[0].node.events
    .filter(event => event.holiday === 'Yes')
    .map(event => event.event_date);

  // State-related.
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDates, setSelectedDate] = useState({
    startDate: { value: '', label: '' },
    endDate: { value: '', label: '' },
  });

  const handleSymbolChange = option => {
    setSelectedSymbol(option);
  };

  const handleDateChange = (option, props) => {
    setSelectedDate({
      ...selectedDates,
      [props.name]: {
        value: option.value,
        label: option.label,
      },
    });
  };

  // Defining variables for dropdowns.
  const today = new Date();
  const expDate = findContractExpirationDate(5, 3, today);
  const frontMonth =
    today <= expDate
      ? moment.tz('America/Chicago')
      : moment.tz('America/Chicago').add(1, 'month');
  const backMonth = moment
    .tz(frontMonth, 'America/Chicago')
    .add(1, 'month')
    .format('MMMM');
  const years = historicalContractsYears();

  const futuresSymbols = buildAllFuturesSymbols(backMonth, years);
  const symbolOptions = [{ value: 'all', label: 'All Symbols' }];
  futuresSymbols.split(',').forEach(function(symbol) {
    symbolOptions.push({
      value: symbol,
      label: trimFuturesSuffix(symbol),
    });
  });

  const initialDateOption = moment.tz(
    FUTURES_START_DATE_FORMATTED,
    'America/Chicago'
  );
  const endDateOption = historicalContractsEndDate();
  const dateOptions = dateSelectOptions(
    initialDateOption.format('MM/DD/YYYY'),
    endDateOption,
    holidays
  );

  // Setup for fetching historical contracts data.
  const fromDate = initialDateOption.format('YYYYMMDD');
  // We add 1 day into the future here so that we make sure we get
  // THO (trading hours only) events that are midday, since dxFeed API considers
  // the toDate to have a time cutoff of 00:00:00 when sending only a date.
  const toDate = moment
    .tz('America/Chicago')
    .add(1, 'day')
    .format('YYYYMMDD');
  const [contractData, loading] = useFetchContractData(
    futuresSymbols,
    fromDate,
    toDate
  );

  return (
    <Layout>
      <SEO
        title={seo_title || pageName}
        description={seo_meta_description}
        lang={lang}
      />
      <MainCol lg={12} md={12} page={'true'}>
        <ColumnWrapper subnav={false}>
          <ContractsHeader
            title={page_title}
            tooltip={page_title_tooltip}
            dateOptions={dateOptions}
            symbolOptions={symbolOptions}
            selectedDates={selectedDates}
            selectedSymbol={selectedSymbol}
            handleDateChange={handleDateChange}
            handleSymbolChange={handleSymbolChange}
          />
          <WidgetContainer>
            {loading ? (
              <SpinnerContainer>
                <Spinner style={{ width: '4rem', height: '4rem' }} />
              </SpinnerContainer>
            ) : (
              <ContractsData
                contractData={contractData}
                futuresSymbols={futuresSymbols}
                selectedDates={selectedDates}
                selectedSymbol={selectedSymbol}
              />
            )}
          </WidgetContainer>
        </ColumnWrapper>
      </MainCol>
    </Layout>
  );
}

export const query = graphql`
  query ContractsPageQuery($uid: String!, $lang: String!) {
    prismic {
      contracts_page(uid: $uid, lang: $lang) {
        page_title
        page_title_tooltip
        seo_meta_description
        seo_title
        _meta {
          lang
          uid
        }
      }
      allEvents {
        edges {
          node {
            events {
              holiday
              event_date
              event_title
            }
          }
        }
      }
    }
  }
`;

export default ContractsPage;
